<template lang="pug">
#transfer
  TransferHero
    TransferSearchForm.mt-4
  #benefits.my-4
    .container-lg
      b-row
        b-col(sm="12")
          h1 How the service works
        b-col.benefit(md="12" lg="4")
          .icon-wrapper
            .icon 1
            .line
          .wrapper
            .title Choose your route and car
            .desc You can choose your car according to the number of people. We provide you a quality service with our modern and comfortable vehicles in different capacities from one person to 13 people.
        b-col.benefit(md="12" lg="4")
          .icon-wrapper
            .icon 2
            .line
          .wrapper
            .title Make your reservation and choose a payment method
            .desc Specify the pickup date and time and choose a payment method. You can use our online payment or in-car payment options while making your payment. After this step, you will be able to access your reservation coupon via e-mail.
        b-col.benefit(md="12" lg="4")
          .icon-wrapper
            .icon 3
            .line
          .wrapper
            .title Meet with Konuk’s driver!
            .desc We will be waiting for you at the right place at the airport with a nameplate. We will be happy to get you to your destination on time.

  #how-its-work.my-3
    .container
      .main-wrapper
        h2.text-center How Does It Work?
        b-row
          b-col.d-flex.justify-content-center.mb-3(md="6" lg="3")
            .benefit-item.c1
              .circle_1
                .circle_2
                  img(src="@/assets/01.svg")
                  .title Book #[br] Online
          b-col.d-flex.justify-content-center.mb-3(md="6" lg="3")
            .benefit-item.c2
              .circle_1
                .circle_2
                  img(src="@/assets/02.svg")
                  .title Recieve #[br] Confirmation
          b-col.d-flex.justify-content-center.mb-3(md="6" lg="3")
            .benefit-item.c3
              .circle_1
                .circle_2
                  img(src="@/assets/03.svg")
                  .title Meet Your #[br] Driver
          b-col.d-flex.justify-content-center.mb-3(md="6" lg="3")
            .benefit-item.c4
              .circle_1
                .circle_2
                  img(src="@/assets/04.svg")
                  .title Arrive At Your #[br] Destiation

  #reviews.my-5.py-5
    .container
      h3.mb-0 Reviews
      .carousel-wrapper
        swiper(:options='swiperOption' ref="swiper")
          swiper-slide
            .wrapper
              .idea.mb-2 “Great Communication”
              p.comment The driver was very kind and helpful. I would like to state that I am particularly pleased with communication. I would like to state that I am pleased with communication and getting answers to my questions. We are happy with the service. Great communication. Thanks for the great experience!
              .name - Sandro Heuberger
          swiper-slide
            .wrapper
              .idea.mb-2 “Absolutely Recommend”
              p.comment I used Konuk Transfer for my trip. The driver arrived on time, was very friendly. The taxi was immaculate and comfortable. Got me door to door in great time. Absolutely recommend this service. Thank you.
              .name - Annabel Acton
          swiper-slide
            .wrapper
              .idea.mb-2 “Friendly, Clean and Professional”
              p.comment 100% Recommend. For friendly, clean, professional driver service I found my new address for me and my family. I will absulutelly use Konuk Transfer again.
              .name - Victoria Colins
          swiper-slide
            .wrapper
              .idea.mb-2 “Excellent Service”
              p.comment I made the reservation in a short time in a simple way. My voucher was sent to my mail. It has been delivered in a way that is provided from the delivery you specified. Excellent service, absolute professionalism
              .name - John Kang
          swiper-slide
            .wrapper
              .idea.mb-2 “Absolutely Perfect”
              p.comment I have done two way transfer between the Istanbul Airport and Sura Design Hotel. My first two trips were absolutely perfect. I will use Konuk Transfer again. I highly recommend Konuk.
              .name - Jones Hessie
          swiper-slide
            .wrapper
              .idea.mb-2 “Easy, Professional, fast and on time”
              p.comment Excellent transport company. Easy, Professional, fast and on time! I traveled from Sabiha Gokcen Airport to Sura Hotel. Pleasant, pricate and excellent transport. We will definitely use this company again.
              .name - Salem Eyeru
          swiper-slide
            .wrapper
              .idea.mb-2 “Polite and Clean”
              p.comment Excellent! they picked us up at the airport. They were really polite and the transfer was so clean. Our driver really friendly. I will definitely book again!
              .name - Hugo Garcias
          .swiper-pagination(slot="pagination")
        .prev.d-none.d-md-block(slot='button-prev' @click='swiper.slidePrev()')
          svg(width='20' height='38' viewbox='0 0 20 38' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M3.44831 19.0088L19.5817 2.49568C20.1394 1.92472 20.1394 0.999053 19.5817 0.428092C19.0238 -0.142697 18.1195 -0.142697 17.5616 0.428092L0.418247 17.975C-0.139416 18.546 -0.139416 19.4717 0.418247 20.0426L17.5616 37.5896C18.1292 38.1506 19.0336 38.1345 19.5817 37.5536C20.1163 36.9869 20.1163 36.0885 19.5817 35.522L3.44831 19.0088Z' fill='#ff8002')
        .next.d-none.d-md-block(slot='button-next' @click='swiper.slideNext()')
          svg(width='20' height='38' viewbox='0 0 20 38' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M19.578 17.9572L2.44953 0.429717C1.88833 -0.144214 0.979743 -0.14325 0.419488 0.432686C-0.140333 1.00855 -0.138887 1.9414 0.422381 2.51578L16.5313 19.0001L0.421802 35.4843C-0.139393 36.0587 -0.14084 36.991 0.418909 37.5669C0.69976 37.8556 1.06769 38 1.43563 38C1.80262 38 2.16911 37.8566 2.44946 37.5699L19.578 20.0428C19.8483 19.7669 20 19.3913 20 19.0001C20 18.6088 19.8479 18.2337 19.578 17.9572Z' fill='#ff8002')
  #FAQ.my-5
    .container
      b-row
        b-col(lg="8")
          h3.mb-2 FAQ
          .accordion(role='tablist')
            .collapse-area.mb-1(no-body v-for="(f, fIndex) in sortFaq" :key="fIndex")
              .header.p-1(header-tag='header' role='tab')
                .head(block v-b-toggle="`accordion-${fIndex}`" variant='info') {{ f.name }}
              b-collapse(:id="`accordion-${fIndex}`" :visible='fIndex === 0' accordion='my-accordion' role='tabpanel')
                .content {{ f.desc }}
          .text-center.mt-4
            b-button.mb-4.mb-lg-0.all-question(variant="warning" @click="allQuestionModal = !allQuestionModal") All Questions
        b-col(lg="4")
          .help
            strong.d-block.mb-3 For any questions
            .list-item.mb-2
              a(href="mailto:support@konuk.com")
                svg.mr-2(xmlns='http://www.w3.org/2000/svg' width='20' height='16' fill='none' viewbox='0 0 20 16')
                  path(fill='#805400' fill-opacity='.4' fill-rule='evenodd' d='M20 2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2zm-2 0l-8 5-8-5h16zm-8 7L2 4v10h16V4l-8 5z' clip-rule='evenodd')
                | support@konuk.com
            .list-item.mb-4
              a(href="mailto:support@konuk.com")
                svg.mr-2(xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewbox='0 0 20 20')
                  path(fill='#805400' fill-opacity='.4' fill-rule='evenodd' d='M14 0H1C.45 0 0 .45 0 1v14l4-4h10c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1zm-1 2v7H3.17L2 10.17V2h11zm4 2h2c.55 0 1 .45 1 1v15l-4-4H5c-.55 0-1-.45-1-1v-2h13V4z' clip-rule='evenodd')
                | Ask our consultant

            strong.d-block.mb-3 Question on the existing booking
            .list-item
              svg.mr-2(xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewbox='0 0 14 14')
                path(fill='#805400' fill-opacity='.4' fill-rule='evenodd' d='M7 0C3.15 0 0 3.15 0 7s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7zm0 12.6A5.607 5.607 0 0 1 1.4 7c0-3.087 2.513-5.6 5.6-5.6 3.087 0 5.6 2.513 5.6 5.6 0 3.087-2.513 5.6-5.6 5.6zm-.7-9.1h1.05v3.64l3.15 1.89-.56.91L6.3 7.7V3.5z' clip-rule='evenodd')
              | We are available 24/7
              br
            a.d-block(href="tel:5342616500") +90 (534) 261 65 00
            small Toll calls

  b-modal( v-model="allQuestionModal" size="lg" hide-footer title="All Questions")
    h2 Transfer Booking

    strong How do I find and book a transfer?
    p Specify the place of departure in the "From" field, and your destination - in the "To" field. You can choose the desired destination, and hotel from the drop-down menu or enter the exact address of the place.
      |Click the button "View prices". Choose the suitable transfer class and click "Book" to proceed to the booking.

    strong How can I book a transfer to the airport?
    p In the "Pick-up" field, select the hotel from which you need to get to the airport. Enter the name of the airport in the “Drop-off” field.
      | Important note: calculate your pick-up time taking into account the time en route (it is specified on the website) + 3 hours for the check-in at the airport.

    strong What pick-up time should I set?
    p If you need a transfer from an airport, we recommend you to specify your flight arrival time as the beginning of your transfer. In this case, it is more convenient for a driver to organize the meeting.
      | Important note: if you were notified about a change of arrival time, please let us know so we could also inform the carrier.
      | If you need a transfer from a hotel to an airport, specify the time you should be met at the reception. You will have to calculate the departure time by yourself. We recommend you to have a time margin of 3 hours for the airport check-in and travel time. Approximate time a driver needs to arrive at the airport is specified on the booking page.
      | For example, if your flight departure time is 15:00 and the travel time specified on site is 1 hour, the pick-up time should be 11:00.

    strong How long does a trip take?
    p You can find the time en-route on our website and in your voucher confirming your booking (pdf file, downloadable after making a booking).
      | Important note: this duration is approximate and can vary depending on the traffic, time and date of transfer, public holidays etc.
      | Please, make changes in your order in advance if you receive new information about traffic situation.

    strong How do I put my flight number correctly?
    p When you book a transfer from the airport, you need to specify your flight number with both letter and numeral parts.
      | For example, TK1070 is a full flight number, and 1070 is incomplete.
      | If you give us an incorrect flight number, our driver will not be able to meet you at the airport on time.

    strong How far in advance should I book a transfer?
    p If you want to book vehicles of Classic or Standard, you can make a booking not less than 24 hours before the trip.
      | If your trip starts later than the time given above, you can make an urgent booking. It will take some time for us to find a car. We will inform you as soon as possible if we can provide the service.

    strong How do I book a return transfer?
    p You can book your return transfer when booking a one-way trip. To do it, specify the date and time of the return transfer in the special field in the booking form.
      | If the route of the return transfer differs from your previous trip, you have to make two different reservations.

    strong How do I know if my booking has gone through?
    p After you make your booking, you will receive a confirmation email with a voucher containing your booking number and all the details of your journey.
      | If you have not received the email with the confirmation, please contact our manager by email at support@konuk.com

    h2 Payment

    strong How can I pay my order?
    p Depending on the country of transfer, there are the following payment options:
      | Cash to the driver. Prepayment on the website is not required, you give the full amount to the driver at the pick-up site.
      | You can also make a full payment online (coming soon).

    strong Can I make a payment as a legal entity?
    p If you need to pay the booking by invoice, contact the manager by email at  support@konuk.com.

    strong What currency will I be charged in?
    p Your card will be charged in euros. Currency of payment is specified on the right side, where you select a payment option.
      | Currency exchange rate depends on your bank and can vary.

    strong What payment options are available?
    p We accept cash to driver payment. Also, online payment is coming soon.

    strong Is it safe to make a payment via your site?
    p Yes. The payment is received by the bank, certified by VISA/MasterCard/AMEX.

    strong What currency should I pay my driver in?
    p Please pay in the currency specified in your voucher.
      | Important note: you should prepare the required amount in cash in advance.
      | You can discuss payment in another currency with your driver, but he/she has the right to refuse and ask you to exchange money.

    strong Will I have to pay extra for waiting?
    p If you are booking a transfer from the airport to the city, the price already includes one hour of waiting after the scheduled arrival time. If your flight is being delayed for less than 30 minutes, the driver will monitor it and arrive by the time of landing. If you learn about a longer flight delay, please inform the driver as soon as possible by calling one of the phone numbers sent to your email.
      | Please note that if the waiting time exceeds 1 hour, the driver may ask to pay extra for waiting.
      | If you book a transfer from the hotel, the price includes 15 minutes of waiting from the time stated in the order.

    strong Is the price for a car or for a passenger?
    p All prices displayed on the website are provided per car. The price does not depend on the number of passengers within the car capacity. Important note: maximum capacity of a car is specified for each class.

    strong Does the price depend on the date of my transfer?
    p Konuk transfer price does not depend on weekends and time of the day. This is what differs this service from taxi services and local taxis.
      | For example, with Konuk Transfer, it is possible to order a taxi transfer from the airport to the city for any day at a price indicated on the website now. The only limitation is rules of minimum booking time.
      | Important note: on some holidays or large-scale events (such as New year, Olympics or World Cup), the price may rise due to increased demand.

    strong Payment issue
    p Please send us an email at support@konuk.com. describing the difficulties encountered. We will try to solve the problem in the next few days.

</template>

<script>
// @ is an alias to /src
import TransferHero from '@/components/TransferHero.vue'
import TransferSearchForm from '@/components/TransferSearchForm.vue'

export default {
  name: 'Home',
  components: {
    TransferHero,
    TransferSearchForm
  },
  data () {
    return {
      allQuestionModal: false,
      sortFaq: [
        {
          name: 'How do I find and book a transfer?',
          desc: 'Specify the place of departure in the "From" field, and your destination - in the "To" field. You can choose the desired destination, and hotel from the drop-down menu or enter the exact address of the place.Click the button "View prices". Choose the suitable transfer class and click "Book" to proceed to the booking.'
        },
        {
          name: 'How can I book a transfer to the airport?',
          desc: 'In the "Pick-up" field, select the hotel from which you need to get to the airport. Enter the name of the airport in the “Drop-off” field. Important note: calculate your pick-up time taking into account the time en route (it is specified on the website) + 3 hours for the check-in at the airport.'
        },
        {
          name: 'How can I pay my order?',
          desc: 'Depending on the country of transfer, there are the following payment options: Cash to the driver. Prepayment on the website is not required, you give the full amount to the driver at the pick-up site. You can also make a full payment online (coming soon).'
        },
        {
          name: 'What payment options are available?',
          desc: 'We accept cash to driver payment. Also, online payment is coming soon.'
        },
        {
          name: 'Is the price for a car or for a passenger?',
          desc: 'All prices displayed on the website are provided per car. The price does not depend on the number of passengers within the car capacity. Important note: maximum capacity of a car is specified for each class. '
        }
      ],
      faq: [
        // {
        //   name: 'How do I find and book a transfer?',
        //   desc: 'Specify the place of departure in the "From" field, and your destination - in the "To" field. You can choose the desired destination, and hotel from the drop-down menu or enter the exact address of the place.Click the button "View prices". Choose the suitable transfer class and click "Book" to proceed to the booking.'
        // },
        // {
        //   name: 'How can I book a transfer to the airport?',
        //   desc: 'In the "Pick-up" field, select the hotel from which you need to get to the airport. Enter the name of the airport in the “Drop-off” field. Important note: calculate your pick-up time taking into account the time en route (it is specified on the website) + 3 hours for the check-in at the airport.'
        // },
        {
          name: 'What pick-up time should I set?',
          desc: 'If you need a transfer from an airport, we recommend you to specify your flight arrival time as the beginning of your transfer. In this case, it is more convenient for a driver to organize the meeting.Important note: if you were notified about a change of arrival time, please let us know so we could also inform the carrier. If you need a transfer from a hotel to an airport, specify the time you should be met at the reception. You will have to calculate the departure time by yourself. We recommend you to have a time margin of 3 hours for the airport check-in and travel time. Approximate time a driver needs to arrive at the airport is specified on the booking page. For example, if your flight departure time is 15:00 and the travel time specified on site is 1 hour, the pick-up time should be 11:00.'
        },
        {
          name: 'How long does a trip take?',
          desc: 'You can find the time en-route on our website and in your voucher confirming your booking (pdf file, downloadable after making a booking). Important note: this duration is approximate and can vary depending on the traffic, time and date of transfer, public holidays etc. Please, make changes in your order in advance if you receive new information about traffic situation.'
        },
        {
          name: 'How do I put my flight number correctly?',
          desc: 'When you book a transfer from the airport, you need to specify your flight number with both letter and numeral parts. For example, TK1070 is a full flight number, and 1070 is incomplete. If you give us an incorrect flight number, our driver will not be able to meet you at the airport on time.'
        },
        {
          name: 'How far in advance should I book a transfer?',
          desc: 'If you want to book vehicles of Standard or Comfort, you can make a booking not less than 24 hours before the trip. If your trip starts later than the time given above, you can make an urgent booking. It will take some time for us to find a car. We will inform you as soon as possible if we can provide the service.'
        },
        {
          name: 'How do I book a return transfer?',
          desc: 'You can book your return transfer when booking a one-way trip. To do it, specify the date and time of the return transfer in the special field in the booking form. If the route of the return transfer differs from your previous trip, you have to make two different reservations.'
        },
        {
          name: 'How do I know if my booking has gone through?',
          desc: 'After you make your booking, you will receive a confirmation email with a voucher containing your booking number and all the details of your journey. If you have not received the email with the confirmation, please contact our manager by email at support@konuk.com'
        },
        // {
        //   name: 'How can I pay my order?',
        //   desc: 'Depending on the country of transfer, there are the following payment options: Cash to the driver. Prepayment on the website is not required, you give the full amount to the driver at the pick-up site. You can also make a full payment online (coming soon).'
        // },
        {
          name: 'Can I make a payment as a legal entity?',
          desc: 'If you need to pay the booking by invoice, contact the manager by email at  support@konuk.com.'
        },
        {
          name: 'What currency will I be charged in?',
          desc: 'Your card will be charged in euros. Currency of payment is specified on the right side, where you select a payment option. Currency exchange rate depends on your bank and can vary.'
        },
        // {
        //   name: 'What payment options are available?',
        //   desc: 'We accept cash to driver payment. Also, online payment is coming soon.'
        // },
        {
          name: 'Is it safe to make a payment via your site?',
          desc: 'Yes. The payment is received by the bank, certified by VISA/MasterCard/AMEX.'
        },
        {
          name: 'What currency should I pay my driver in?',
          desc: 'Please pay in the currency specified in your voucher. Important note: you should prepare the required amount in cash in advance. You can discuss payment in another currency with your driver, but he/she has the right to refuse and ask you to exchange money.'
        },
        {
          name: 'Will I have to pay extra for waiting?',
          desc: 'If you are booking a transfer from the airport to the city, the price already includes one hour of waiting after the scheduled arrival time. If your flight is being delayed for less than 30 minutes, the driver will monitor it and arrive by the time of landing. If you learn about a longer flight delay, please inform the driver as soon as possible by calling one of the phone numbers sent to your email. Please note that if the waiting time exceeds 1 hour, the driver may ask to pay extra for waiting. If you book a transfer from the hotel, the price includes 15 minutes of waiting from the time stated in the order.'
        },
        // {
        //   name: 'Is the price for a car or for a passenger?',
        //   desc: 'All prices displayed on the website are provided per car. The price does not depend on the number of passengers within the car capacity. Important note: maximum capacity of a car is specified for each class. '
        // },
        {
          name: 'Does the price depend on the date of my transfer?',
          desc: 'Konuk transfer price does not depend on weekends and time of the day. This is what differs this service from taxi services and local taxis. For example, with Konuk Transfer, it is possible to order a taxi transfer from the airport to the city for any day at a price indicated on the website now. The only limitation is rules of minimum booking time. Important note: on some holidays or large-scale events (such as New year, Olympics or World Cup), the price may rise due to increased demand.'
        },
        {
          name: 'Payment issue',
          desc: 'Please send us an email at support@konuk.com. describing the difficulties encountered. We will try to solve the problem in the next few days.'
        }
      ],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: false,
          clickable: true
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 0
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 0
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 0
          }
        }
      }
    }
  },
  computed: {
   swiper () {
        return this.$refs.swiper.$swiper
    }
  },
  mounted () {
    console.log(this.$refs)
  }
}
</script>

<style lang="scss">
#how-its-work .main-wrapper .benefit-item::after{
  @media (max-width: 991px) {
    content: none !important;
  }
}
#how-its-work{
  .main-wrapper{
    background-image: url('~@/assets/world_2.png');
    min-height: 300px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0 100px 0;
    // @media (max-width: 991px) {
    //   &::after{
    //     content: none !important;
    //   }
    // }
    h2{
      font-size: 40px;
      margin-bottom: 70px;
    }
    .benefit-item{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 200px;
      width: fit-content;
      height: fit-content;
      border: 2px solid white;
      .circle_1{
        border: 15px solid #909090;
        width: fit-content;
        height: fit-content;
        border-radius: 200px;
        .circle_2{
          display: flex;
          flex-direction: column;
          // background-color: gainsboro;
          border-radius: 300px;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 200px;
          box-shadow: inset 0px 0px 10px 0px rgb(0 0 0 / 28%);
          border: 5px solid white;
          img{
            width: 50px;
            height: 50px;
          }
          .title{
            font-weight: bold;
            text-align: center;
            font-size: 20px;
            line-height: 20px;
            margin-top: 10px;
          }
        }
      }
      // @media (max-width: 991) {
      //   &::after{
      //     display: none !important;
      //   }
      // }

      &.c1{
        border-color: #F75810;
        .circle_1{
          border-color: white;
          .circle_2{
            border-color: #F75810;
          }
        }
        // @media (min-width: 992) {
          &::after{
            content: "";
            background-image: url('~@/assets/arr.svg');
            width: 100px;
            height: 20px;
            position: absolute;
            background-repeat: no-repeat;
            background-position: center center;
            right: -50px;
            top: 0px;
          }
        // }
      }

      &.c2{
        border-color: #8E8785;
        .circle_1{
          border-color: white;
          .circle_2{
            border-color: #8E8785;
          }
        }
        &::after{
          content: "";
          background-image: url('~@/assets/arr.svg');
          width: 100px;
          height: 20px;
          position: absolute;
          background-repeat: no-repeat;
          background-position: center center;
          right: -50px;
          bottom: 0px;
          transform: rotate(180deg) scale(-1, 1);
        }
      }

      &.c3{
        border-color: #FF8001;
        .circle_1{
          border-color: white;
          .circle_2{
            border-color: #FF8001;
          }
        }
        &::after{
          content: "";
          background-image: url('~@/assets/arr.svg');
          width: 100px;
          height: 20px;
          position: absolute;
          background-repeat: no-repeat;
          background-position: center center;
          right: -50px;
          top: 0px;
        }
      }

      &.c4{
        border-color: #A7A9AC;
        .circle_1{
          border-color: white;
          .circle_2{
            border-color: #A7A9AC;
          }
        }
        // &::after{
        //   content: "";
        //   background-image: url('~@/assets/arr.svg');
        //   width: 100px;
        //   height: 20px;
        //   position: absolute;
        //   background-repeat: no-repeat;
        //   background-position: center center;
        //   right: -50px;
        //   top: 0px;
        // }
      }

    }
  }
}

#FAQ{
  .header{
    padding: 0 !important;
    border: none;
    .head{
      display: flex;
      color: black;
      align-items: center;
      font-weight: bold;
    }
  }
  .content{
    padding: 20px 0px;
  }
  button.all-question{
    background-color: #ff8002;
    border-color: #ff8002;
    color: #ffffff;
    &:hover{
      background-color: #c76401;
    }
  }
  .help{
    --text-colors: white;
    background-color: #a7a9ac;
    color: var(--text-colors);
    border-radius: 12px;
    padding: 14px;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    a{
      color: var(--text-colors);
    }
    svg{
      path{
        fill: var(--text-colors);
      }
    }
  }
}

#benefits{
  .benefit{
    position: relative;
    .icon-wrapper{
      position: relative;
      margin-bottom: 20px;
      .icon{
        background-color: #ff8001;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        position: relative;
      }
      .line{
        width: calc(100% - 40px);
        height: 2px;
        background-color: #ffcd9b;
        position: absolute;
        top: calc(50% - 2px);
        left: 40px;
        padding-right: 40px;
      }

    }
    .wrapper{
      .title{
        font-weight: bold;
        margin-bottom: 10px;
      }
      .desc{
        font-weight: 200;
      }
    }
  }

  @media (max-width: 991px){
    h1{
      font-size: 20px;
    }
    .benefit{
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      .icon-wrapper{
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        padding-right: 10px;;
        .icon{
          // width: 40px;
          // height: 80px;
        }
        .line{
          position: relative;
          height: 100%;
          top: 0;
          left: calc(50% - 2px);
          width: 4px;
          padding: 0;
        }
      }
      .wrapper{
        .title{
        }
        .desc{
        }
      }
    }

  }

}

#reviews{
  background-color: #f6f7f8;
  .carousel-wrapper{
    position: relative;
    .prev{
      position: absolute;
      top: calc(50% - 20px);
      z-index: 19;
      left: -50px;
    }
    .next{
      position: absolute;
      top: calc(50% - 20px);
      z-index: 19;
      right: -50px;
    }
    .swiper-pagination{
      @media (min-width: 768px) {
        display: none;
      }
      .swiper-pagination-bullet-active{
        background-color: #ff8002;
      }
    }
  }
  .swiper-wrapper{
    padding: 50px 0px;
  }
  .swiper-slide{
    padding: 0 10px;
    height: auto;

    .wrapper{
      height: 100%;
      padding: 24px;
      font-size: 14px;
      line-height: 1.63;
      color: #373f47;
      border-radius: 12px;
      background-color: #fff;
      box-shadow: 0px 10px 10px 0px rgba(55, 63, 71, .5);
      display: flex;
      flex-direction: column;
      justify-content: center;
      // border: 1px solid rgb(245, 245, 245);
      .idea{
        font-weight: bold;
      }
      .name{
        color: #6c7c8c;
      }
    }
  }
  .swiper-button-prev, .swiper-container-rtl .swiper-button-next{
    background-color: rgba(255, 255, 255, 0.7);
    padding: 10px;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    box-shadow: 0 12px 24px 0 #ced7e0;
    &::after{
      font-size: 20px;
      color: black;
    }
  }
}

</style>
